import * as CONSTANT from "./constants";

const INIT_STATE = {
    account: {},
    nfts: [],
    allNfts: [],
    token: {},
    tokenRecords: []
};

const Profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANT.SET_NFT:
            return {
                ...state,
                nfts: action.payload.nfts,
            };
        case CONSTANT.SET_ACCOUNT_INFO:
            return {
                ...state,
                account: action.payload.accountInfo,
            };
        case CONSTANT.SET_ALL_NFT:
            return {
                ...state,
                allNfts: action.payload.allNfts,
            };
        case CONSTANT.UPDATE_TOKEN_COUNT:
            return {
                ...state,
                token: action.payload
            }
        case CONSTANT.UPDATE_TOKEN_RECORD:
            return {
                ...state,
                tokenRecords: action.payload
            }

        default:
            return state;
    }
};

export default Profile;
