import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    USER_SWITCH,
    IS_VISITOR,
    SET_INVITER,
    SET_CHAIN_TYPE,
} from "./constants";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    showAuthTip: false,
	isVisitor: false,
    inviter: "",
    chainType: "",
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
                chainType: action.payload.ChainType
            };

        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
            };

        case LOGOUT_USER:
            return { ...state, user: null };

        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetStatus: action.payload,
                loading: false,
                error: null,
            };

        case API_FAILED:
            return { ...state, loading: false, error: action.payload };

        case USER_SWITCH:
            return { ...state, showAuthTip: action.payload };
        case IS_VISITOR:
            return {
                ...state,
                isVisitor: true,
            }
        case SET_INVITER: 
            return {
                ...state,
                inviter: action.payload
            }
        case SET_CHAIN_TYPE:
            return {
                ...state,
                chainType: action.payload.chainType
            }

        default:
            return { ...state };
    }
};

export default Auth;
