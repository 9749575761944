export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const QUOTE_ID = "QUOTE_ID";
export const UPDATE_ERROR = "UPDATE_ERROR";
export const UPDATE_CURRENT_MESSAGES = "UPDATE_CURRENT_MESSAGES";
export const ADD_URL_META = "ADD_URL_META";
export const RE_UPLOAD_IMAGE = "RE_UPLOAD_IMAGE";
export const UPDATE_UPLOAD_IMAGE = "UPDATE_UPLOAD_IMAGE";
export const DELETE_MESSAGE_BY_ID = "DELETE_MESSAGE_BY_ID";
export const UPDATE_MESSAGE_NORMAL = "UPDATE_MESSAGE_NORMAL";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const SET_GROUPS = "SET_GROUPS";
export const FETCH_GROUP_MESSAGES = "FETCH_GROUP_MESSAGES";
export const UPDATE_GROUP_MESSAGES = "UPDATE_GROUP_MESSAGES";
export const FETCH_GROUP_MEMBERS = "FETCH_GROUP_MEMBERS";
export const UPDATE_GROUP_MEMBERS = "UPDATE_GROUP_MEMBERS";
export const UPDATE_ACTIVATED_GROUP_ID = "UPDATE_ACTIVATED_GROUP_ID";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const FETCH_BEFORE_MESSAGES = "FETCH_BEFORE_MESSAGES";
export const FETCH_AFTER_MESSAGES = "FETCH_AFTER_MESSAGES";
export const UPDATE_BEFORE_ENABLE = "UPDATE_BEFORE_ENABLE";
export const UPDATE_LOADING_MESSAGE = "UPDATE_LOADING_MESSAGE";
export const GET_LAST_READ_MESSAGE = "GET_LAST_READ_MESSAGE";
export const SET_LAST_READ_MESSAGE = "SET_LAST_READ_MESSAGE";
export const UPDATE_LAST_READ_MESSAGE_TIMESTAMP = "UPDATE_LAST_READ_MESSAGE_TIMESTAMP";
export const UPDATE_LATEST_MESSAGE_TIMESTAMP = "UPDATE_LATEST_MESSAGE_TIMESTAMP";
export const UPDATE_VIEW_DIRECTION = "UPDATE_VIEW_DIRECTION";
// stick emoji
export const STICK_EMOJI = "STICK_EMOJI";
export const UNDO_STICK_EMOJI = "UNDO_STICK_EMOJI";
export const TRIGGER_CHANGE_GROUP = "TRIGGER_CHANGE_GROUP";

export const NO_MESSAGES = "NO_MESSAGES";
export const FETCH_STICKER = "FETCH_STICKER";
export const SET_STICKER = "SET_STICKER";

export const HANDLE_WS_MESSAGE = "HANDLE_WS_MESSAGE";
export const START_AFTER_POLL = "START_AFTER_POLL";

export const SET_BEFORE_MESSAGES = "SET_BEFORE_MESSAGES";
export const UPDATE_OLDEST_MESSAGE_TIME = "UPDATE_OLDEST_MESSAGE_TIME";

export const SET_NO_MORE_MESSAGE = "SET_NO_MORE_MESSAGE";

export const USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES = "USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES";
export const RESET_GROUP_UNREAD_COUNT = "RESET_GROUP_UNREAD_COUNT";

export const UPDATE_SENDER_MESSAGE = "UPDATE_SENDER_MESSAGE";
export const REDUCE_CURRENT_MESSAGES = "REDUCE_CURRENT_MESSAGES";

export const UPDATE_CHANNEL_MEMBER = "UPDATE_CHANNEL_MEMBER";
export const UPDATE_GROUP_UNREAD_COUNT = "UPDATE_GROUP_UNREAD_COUNT";
export const UPDATE_NFT_MARKETPLACE = "UPDATE_NFT_MARKETPLACE";
export const UPDATE_GROUP_BY_ID = "UPDATE_GROUP_BY_ID";
