import { AUTH_USER_KEY, AUTH_USER_LIST_KEY } from ".";

/**
 * clear the storage of authUser and update authUsers
 */
const clearCurrentUserStorage = () => {
    const authUsersStorage = localStorage.getItem(AUTH_USER_LIST_KEY);
    const authUserStorage = localStorage.getItem(AUTH_USER_KEY);
    const authUser = JSON.parse(authUserStorage);
    const authUsers = authUsersStorage ? JSON.parse(authUsersStorage) : [];
    const newUserStorage = authUsers.filter((user) => {
        return user.Address !== authUser.Address;
    });
    localStorage.setItem(AUTH_USER_LIST_KEY, JSON.stringify(newUserStorage));
    localStorage.removeItem(AUTH_USER_KEY);
};
/**
 * Checks if user is authenticated 
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const currentTime = Date.now() / 1000;
        if (user.exp < currentTime) {
            console.warn("access token expired");
            clearCurrentUserStorage(user.Address);
            return false;
        } else {
            return true;
        }
    } catch (e) {
        console.warn("access token expired");
        return false;
    }
};

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem(AUTH_USER_KEY);
    return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, setLoggedInUser, getLoggedInUser, clearCurrentUserStorage };
