import React from "react";
import { Redirect } from "react-router-dom";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));

// auth
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Logout = React.lazy(() => import("../pages/Auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/Auth/Register"));
const LockScreen = React.lazy(() => import("../pages/Auth/LockScreen"));
const Visitor = React.lazy(() => import("../pages/Auth/Visitor"));
const NotFound = React.lazy(() => import("../pages/Auth/404"));
const NonAuthToChannel = React.lazy(() => import("../pages/Auth/NonAuthToChannel"));

// declare all routes
const authProtectedRoutes = [
    { path: "/", component: Dashboard },
    { path: "/pages-starter", component: StarterPage },
    { path: "/visitor", component: Visitor },
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/" />,
    }
];

const publicRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forget-password", component: ForgetPassword },
    { path: "/register", component: Register },
    { path: "/lock-screen", component: LockScreen },
    { path: "/landing", component: StarterPage },
    { path: "/404", component: NotFound },
    { path: "/non-auth", component: NonAuthToChannel },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
