import { all, call, delay, fork, put, select, spawn, takeEvery, throttle } from "redux-saga/effects";

import {
    RE_UPLOAD_IMAGE,
    FETCH_GROUPS,
    FETCH_GROUP_MESSAGES,
    FETCH_GROUP_MEMBERS,
    SEND_MESSAGE,
    FETCH_BEFORE_MESSAGES,
    FETCH_AFTER_MESSAGES,
    SET_LAST_READ_MESSAGE,
    STICK_EMOJI,
    UNDO_STICK_EMOJI,
    TRIGGER_CHANGE_GROUP,
    FETCH_STICKER,
    HANDLE_WS_MESSAGE,
    START_AFTER_POLL,
    USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES,
    REDUCE_CURRENT_MESSAGES,
    // UPDATE_ERROR
} from "./constants";
import { OPEN_GROUP_PROFILE_SIDEBAR } from "../layout/constants";
import {
    fetchGroupMembers,
    fetchGroupMessages,
    setGroups,
    updateActivatedGroupId,
    updateChatError,
    updateFetchBeforeEnable,
    updateGroupMembers,
    updateFetchMessageLoading,
    updateGroupMessages,
    // reUploadImage,
    updateUploadImageMessage,
    updateUnreadMessageTimestamp,
    triggerChangeGroup,
    updateNoMessage,
    fetchSticker,
    setSticker,
    handleWSMessage,
    // setBeforeMessages,
    updateOldestMessageTimestamp,
    updateLatestMessageTimestamp,
    setNoMoreMessages,
    setUnreadMessageTimestamp,
    resetGroupUnreadCount,
    updateSenderMessage,
    updateGroupUnreadCount,
    updateMessageNormal,
    updateNftMarketplace,
    fetchAfterMessages,
    updateGroupById,
    deleteMessageById,
} from "./actions";
import {
    fetchMemeApi,
    getChannelInfoApi,
    getChannelMembersApi,
    getChannelRecentMessagesApi,
    getLastReadMessageApi,
    getMessagesAfterTimestampApi,
    getMessagesBeforeTimestampApi,
    getUnreadMessageCountApi,
    getUrlMetaApi,
    sendMessageApi,
    setLastReadMessageApi,
    stickEmojiApi,
    undoStickEmojiApi,
    uploadImageApi,
    // baseUrl,
    lobbyAccountLoginApi,
    lobbyAccountHeartbeatApi,
    getCollectionOverviewApi,
    getChannelByLabelApi,
    getNftOfChannelApi,
} from "../../helpers/api";
import { splitMessagesByDay, TOKEN_KEY } from "../../helpers";
import moment from "moment";
// import { APIClient } from "../../helpers/apiClient";
import { getTokenCount, setIsVisitor, queryAccountInfo } from "../actions";

const MESSAGE_NUMBER = 20;
const RENDER_LIMIT = 40;
// const post = new APIClient().create;
const FETCH_BEFORE_ENABLE_INTERVAL = 1500;

// executers
/**
 * reupload image
 * @param {*} action
 */
function* reUploadImageExecuter(action) {
    try {
        const res = yield call(uploadImageApi, action.payload.formData);
        if (res.location) {
            const message = {
                ...action.payload,
                imageMessage: [{ image: res.location }],
                formData: null,
            };
            yield put(updateUploadImageMessage(message));
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Upload Error: ${error}`));
    }
}

// fetch groups
function* getGroupListExecuter({ payload }) {
    try {
        yield call(loginLobby);
        const res = yield call(getChannelInfoApi);
        const channelLabel = payload;

        if (res && res.channels) {
            yield put(setGroups(res.channels));
            const matchedChannel = res.channels.find((c) => c.label === channelLabel);

            if (channelLabel) {
                const pickedChannelRes = yield call(getChannelByLabelApi, { channel_label: channelLabel });
                if (!matchedChannel && pickedChannelRes.channel_id) {
                    window.location.href = `/non-auth/g/${channelLabel}`;
                }
                if (!matchedChannel && !pickedChannelRes.channel_id) {
                    window.location.href = `/404`;
                }
            }
            const channel = matchedChannel ? matchedChannel.channel_id : res.channels[0].channel_id;

            // set current group id
            yield put(updateActivatedGroupId(channel));
            yield put(triggerChangeGroup(channel));
            yield put(fetchSticker());
            yield put(getTokenCount());
            yield put(queryAccountInfo());

            // start poll
            // yield spawn(groupMessagePoll);
            yield spawn(groupUnreadMessageCountPoll);
            yield spawn(heartbeatForLobbyTimer);
            yield call(fetchGroupsInfo);
        } else {
            yield put(setGroups([]));
            yield put(setIsVisitor());
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// fetch group recent messages
function* fetchGroupMessagesExecuter(action) {
    try {
        const { channel_id: channel } = action.payload;
        const recentMessages = yield call(getChannelRecentMessagesApi, {
            channel,
            count: MESSAGE_NUMBER,
        });

        if (!recentMessages.messages) {
            // no message
            yield put(updateNoMessage(true));
            // enable fetch before
            yield delay(FETCH_BEFORE_ENABLE_INTERVAL);
            yield put(updateFetchBeforeEnable({ enable: true }));
            // yield call(groupFirstMessagePoll, { channel });
            return;
        }

        // hidden no message tips
        yield put(updateNoMessage(false));

        // sort by timestamp
        const sortedMessages = recentMessages.messages.sort((a, b) => {
            return moment(b.timestamp).isBefore(a.timestamp) ? 1 : -1;
        });

        // oldest message
        yield put(updateOldestMessageTimestamp(sortedMessages[0].timestamp));
        yield put(
            updateLatestMessageTimestamp({
                channel,
                timestamp: sortedMessages[sortedMessages.length - 1].timestamp,
            })
        );
        const messageWithExtra = yield fillMessageExtra(sortedMessages);

        // update messages
        yield put(
            updateGroupMessages({
                groupId: channel,
                messages: splitMessagesByDay(messageWithExtra),
            })
        );

        // enable fetch before
        yield delay(FETCH_BEFORE_ENABLE_INTERVAL);
        yield put(updateFetchBeforeEnable({ enable: true }));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// fetch group members
function* fetchGroupMembersExecuter(action) {
    try {
        const { channel_id } = action.payload;
        const groupMembers = yield call(getChannelMembersApi, { channel_id });
        if (!groupMembers.members) {
            return;
        }

        // convert to map
        const memberMap = {};
        groupMembers.members.forEach((member) => {
            const { member_id } = member;
            if (member_id && !memberMap[member_id]) {
                memberMap[member_id] = member;
            }
        });

        // update members
        yield put(
            updateGroupMembers({
                groupId: channel_id,
                members: memberMap,
            })
        );
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// fetch AFTER messages
function* fetchAfterMessagesExecuter() {
    try {
        const { activatedGroupId, groupMessageMap, loadingMessage } = yield select((state) => state.Chat);
        if (loadingMessage) {
            return;
        }
        yield put(updateFetchMessageLoading({ loading: true }));
        const currentChatMessages = groupMessageMap[activatedGroupId] || [];
        if (!currentChatMessages.length) {
            yield put(updateFetchMessageLoading({ loading: false }));
            return;
        }

        const latestMessageTimestamp = currentChatMessages[currentChatMessages.length - 1].timestamp;

        const messagesAfter = yield call(getMessagesAfterTimestampApi, {
            channel: activatedGroupId,
            count: MESSAGE_NUMBER,
            timestamp: latestMessageTimestamp,
        });
        // no update
        if (!messagesAfter.messages || messagesAfter.messages?.length < 2) {
            yield put(updateFetchMessageLoading({ loading: false }));
            return;
        }

        // delete same message
        if (messagesAfter.messages[0].timestamp === latestMessageTimestamp) {
            messagesAfter.messages.shift();
        }

        // update messages
        const newMessages = yield fillMessageExtra(messagesAfter.messages);
        const messages = splitMessagesByDay([...currentChatMessages, ...newMessages]);
        yield put(
            updateGroupMessages({
                groupId: activatedGroupId,
                messages,
            })
        );
        yield put(updateFetchMessageLoading({ loading: false }));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateFetchMessageLoading({ loading: false }));
        yield put(updateChatError(`Error: ${error}`));
    }
}

function* fetchAfterMessage(data) {
    try {
        const { activatedGroupId, groupMessageMap } = yield select((state) => state.Chat);
        const currentChatMessages = groupMessageMap[activatedGroupId];
        const messagesAfter = yield call(getMessagesAfterTimestampApi, data);

        // no update
        if (!messagesAfter.messages || messagesAfter.messages?.length < 2) {
            return;
        }

        // delete same message
        if (messagesAfter.messages[0].timestamp === data.timestamp) {
            messagesAfter.messages.shift();
        }

        // update messages
        const newMessages = yield fillMessageExtra(messagesAfter.messages);
        const messages = splitMessagesByDay([...currentChatMessages, ...newMessages]);
        yield put(
            updateGroupMessages({
                groupId: data.channel,
                messages,
            })
        );
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// fetch BEFORE messages
function* fetchBeforeMessagesExecuter() {
    try {
        const {
            oldestMessageTimestamp,
            fetchBeforeEnable,
            loadingMessage,
            activatedGroupId: channel,
            groupMessageMap,
        } = yield select((state) => state.Chat);

        // prevent auto trigger from first loading
        if (!fetchBeforeEnable || loadingMessage || !oldestMessageTimestamp) {
            return;
        }

        // enable loading
        yield put(updateFetchMessageLoading({ loading: true }));
        yield put(updateFetchBeforeEnable({ enable: false }));

        // fetch message before timestamp
        const beforeMessages = yield call(getMessagesBeforeTimestampApi, {
            channel,
            count: MESSAGE_NUMBER,
            timestamp: oldestMessageTimestamp,
        });

        if (!beforeMessages.messages || beforeMessages.messages.length === 0) {
            // yield put(updateChatError(`Network Error: ${JSON.stringify(beforeMessages)}`));
            yield put(updateFetchMessageLoading({ loading: false }));
            yield put(updateFetchBeforeEnable({ enable: true }));
            return;
        }

        // no more any history messages tips
        if (beforeMessages.messages.length === 1) {
            // unable loading
            yield put(updateFetchMessageLoading({ loading: false }));
            yield put(setNoMoreMessages(true));
            return;
        } else yield put(setNoMoreMessages(false));
        // delete same message
        beforeMessages.messages.shift();

        const { unreadTimestamp } = yield select((state) => state.Chat);
        const currentChatMessages = groupMessageMap[channel] || [];

        // update messages
        const earlyMessages = beforeMessages.messages.reverse();

        // fill message's extra
        const messageWithExtra = yield fillMessageExtra(earlyMessages);

        const tempBeforeMessages = splitMessagesByDay([...messageWithExtra, ...currentChatMessages]);
        // yield put(setBeforeMessages(tempBeforeMessages));
        yield put(updateGroupMessages({ groupId: channel, messages: tempBeforeMessages }));

        // oldest message
        const oldestTimestamp = earlyMessages[0].timestamp;
        yield put(updateOldestMessageTimestamp(oldestTimestamp));

        // history message trigger unread update
        if (unreadTimestamp > oldestTimestamp && unreadTimestamp > -1) {
            // update last read message timestamp
            const lastMessage = currentChatMessages[currentChatMessages?.length - 1];
            const data = {
                channel,
                timestamp: lastMessage && lastMessage.timestamp,
            };

            yield put(setUnreadMessageTimestamp(data));
            yield put(updateGroupUnreadCount({ channel_id: channel, unread: 0 }));
            // hide the new message tip
            yield put(updateUnreadMessageTimestamp({ timestamp: -1 }));
        }

        // unable loading
        yield put(updateFetchMessageLoading({ loading: false }));
        yield delay(FETCH_BEFORE_ENABLE_INTERVAL);
        yield put(updateFetchBeforeEnable({ enable: true }));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateFetchMessageLoading({ loading: false }));
        yield put(updateChatError(`Error: ${error}`));
    }
}

// Group's first message poll
// function* groupFirstMessagePoll({ channel }) {
//     try {
//         const { groupMessageMap } = yield select((state) => state.Chat);
//         const currentChatMessages = groupMessageMap[channel] || [];
//         if (!currentChatMessages || currentChatMessages?.length === 0) {
//             yield put(fetchGroupMessages({ channel_id: channel }));
//             yield delay(1000);
//         }
//     } catch (error) {
//         console.log("saga error: ", error);
//         yield put(updateChatError(`Error: ${error}`));
//     }
// }

// group message poll
function* groupMessagePoll() {
    try {
        while (true) {
            yield delay(1000);

            const { groupMessageMap, activatedGroupId: channel } = yield select((state) => state.Chat);
            const currentChatMessages = groupMessageMap[channel] || [];
            const lastMessage = currentChatMessages[currentChatMessages?.length - 1];

            // jump invalid message
            if (!lastMessage || !lastMessage.timestamp) {
                continue;
            }

            // fetch
            yield call(fetchAfterMessage, {
                channel,
                count: MESSAGE_NUMBER,
                timestamp: lastMessage.timestamp,
            });
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// send message
function* sendMessageExecuter(action) {
    const channel = yield select((state) => state.Chat.activatedGroupId);
    const user = yield select((state) => state.Auth.user);

    // convert message
    const { cmd, payload, quote, fileObj, filename, resend } = action.payload;
    const timestamp = new Date().getTime();
    const message = {
        webMock: true,
        channel_id: channel,
        cmd,
        fileObj,
        from: user.UID,
        likes: null,
        payload,
        quote,
        timestamp,
        filename,
        client_timestamp: timestamp,
    };
    // delete message
    if (resend) {
        yield put(deleteMessageById(action.payload));
    }
    try {
        if (cmd === 2 && !fileObj) {
            // send
            const msgFromServer = yield call(sendMessageApi, {
                channel,
                cmd,
                payload,
                quote,
                timestamp,
                platform: 1,
            });
            // update sender's message
            const newMessages = yield fillMessageExtra([{ ...msgFromServer, client_timestamp: timestamp }]);
            yield put(updateSenderMessage({ ...newMessages[0] }));
            // update no message flag
            yield put(updateNoMessage(false));
            return;
        }

        // add to message list
        yield put(handleWSMessage({ message }));

        // update no message flag
        yield put(updateNoMessage(false));

        // send image after upload
        if (fileObj && fileObj.type.includes("image")) {
            return;
        }

        // send
        const msgFromServer = yield call(sendMessageApi, {
            channel,
            cmd,
            payload,
            quote,
            timestamp,
            platform: 1,
        });
        // update sender's message
        const newMessages = yield fillMessageExtra([{ ...msgFromServer, client_timestamp: timestamp }]);
        yield put(updateSenderMessage({ ...newMessages[0] }));
    } catch (error) {
        console.log("saga error: ", error);
        // send failed
        yield put(updateSenderMessage({ ...message, sendFailed: true }));
    }
}

// fetch group's unread count and last read message
function* updateGroupUnreadMessageCount(group) {
    try {
        const channel = group.channel_id;
        const res = yield call(getUnreadMessageCountApi, { channel });
        const newGroup = {
            ...group,
            unread: res.count,
        };
        yield put(updateGroupUnreadCount(newGroup));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// Get group's unread message count poll
function* groupUnreadMessageCountPoll() {
    try {
        while (true) {
            const groups = yield select((state) => state.Chat.groups);

            // fetch unread count one by one
            for (let i = 0; i < groups?.length; i++) {
                const group = groups[i];
                yield call(updateGroupUnreadMessageCount, group);
            }

            yield delay(1000 * 60 * 5);
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// Set last read message timestamp
function* setLastReadMessageExecuter(action) {
    try {
        const { channel, timestamp } = action.payload;
        yield call(setLastReadMessageApi, { channel, timestamp });
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// Get last read message timestamp
function* getLastReadTimestamp(channel) {
    try {
        const lastRead = yield call(getLastReadMessageApi, { channel });
        yield put(updateUnreadMessageTimestamp({ timestamp: lastRead.timestamp }));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// stick emoji to message
function* stickEmojiExecuter(action) {
    try {
        const res = yield call(stickEmojiApi, action.payload);
        return res;
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// undo stick emoji to message
function* undoStickEmojiExecuter(action) {
    try {
        yield call(undoStickEmojiApi, action.payload);
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// change group
function* changeGroupExecuter(action) {
    try {
        const channel_id = action.payload;
        const { activatedGroupId, groupMessageMap, groups } = yield select((state) => state.Chat);
        const currentMessages = groupMessageMap[activatedGroupId];

        if (currentMessages) {
            const lastMessageTimestamp = currentMessages[currentMessages?.length - 1].timestamp;
            // update last read message
            yield put(
                setUnreadMessageTimestamp({
                    channel: activatedGroupId,
                    timestamp: lastMessageTimestamp,
                })
            );
            // reset current group unread
            groupMessageMap[activatedGroupId] = currentMessages?.filter((m, index) => {
                return index >= currentMessages.length - 100;
            });
            yield put(resetGroupUnreadCount({ channel_id: activatedGroupId }));
        }

        // fetch nft
        const group = groups.find((g) => g.channel_id === channel_id);
        const { nfts = [] } = yield call(getNftOfChannelApi, { channel_id });
        yield put(updateGroupById({ ...group, nfts }));

        // fetch nft marketplace
        for (let i = 0; i < nfts?.length; i++) {
            const nft = nfts[i];
            yield spawn(getNftMarketplace, nft.nft_address);
        }

        // hidden no more message
        yield put(setNoMoreMessages(false));

        // hidden no message
        yield put(updateNoMessage(false));

        yield put(updateFetchBeforeEnable({ enable: false }));

        // reset unread count before change
        yield put(updateGroupUnreadCount({ channel_id: activatedGroupId, unread: 0 }));

        // fetch members
        yield put(fetchGroupMembers({ channel_id }));

        // change group
        yield put(updateActivatedGroupId(channel_id));

        const targetGroupMessages = groupMessageMap[channel_id];
        if (!targetGroupMessages || targetGroupMessages?.length === 0) {
            // fetch messages
            yield put(fetchGroupMessages({ channel_id }));
        } else {
            // update oldest message timestamp
            yield put(updateOldestMessageTimestamp(targetGroupMessages[0].timestamp));
        }

        // get last read message
        yield call(getLastReadTimestamp, channel_id);
        // update url
        window.history.replaceState({}, "", `/g/${group.label}`);

        // enable fetch before
        yield delay(FETCH_BEFORE_ENABLE_INTERVAL);
        yield put(updateFetchBeforeEnable({ enable: true }));
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// get quote message
function* getQuoteMessage(message) {
    try {
        const { activatedGroupId, groupMessageMap } = yield select((state) => state.Chat);
        const currentChatMessages = groupMessageMap[activatedGroupId] || [];
        const { quote, channel_id } = message;

        let quoteMessage = currentChatMessages.find((m) => m.timestamp === Number(quote));
        if (!quoteMessage) {
            const data = {
                channel: channel_id,
                count: 1,
                timestamp: Number(quote),
            };
            const res = yield call(getMessagesBeforeTimestampApi, data);
            quoteMessage = res.messages[0];
        }

        return { ...message, quoteMessage };
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
        return message;
    }
}

// fill message's extra data
function* fillMessageExtra(messages) {
    try {
        const list = [];
        for (let i = 0; i < messages?.length; i++) {
            let msg = messages[i];
            // quote
            if (msg.quote && msg.quote !== "null") {
                msg = yield getQuoteMessage(msg);
            }
            // url meta
            if (msg.cmd === 1 && msg.payload) {
                yield spawn(getUrlMeta, msg);
            }
            list.push(msg);
        }
        return list;
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// get url message's meta data
function* getUrlMeta(msg) {
    try {
        const res = yield call(getUrlMetaApi, msg.payload);
        if (res.data && res.data.site) {
            yield put(updateMessageNormal({ ...msg, urlMeta: res.data }));
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// fetch sticker
function* fetchStickerExecuter() {
    try {
        const res = yield call(fetchMemeApi);
        if (res.memes) {
            yield put(setSticker(res.memes));
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// start after poll
function* startAfterPollExecuter() {
    try {
        yield spawn(groupMessagePoll);
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// web socket message
function* handleWSMessageExecuter({ payload }) {
    try {
        const { message, callback } = payload;
        const channel = message.channel_id;
        const { activatedGroupId, groupMessageMap, groupLatestMessageTimestampMap } = yield select((state) => state.Chat);
        const oldMessages = yield select((state) => {
            return state.Chat.groupMessageMap[channel] || [];
        });
        const group = yield select((state) => state.Chat.groups.find((g) => g.channel_id === channel));

        const currentChatMessages = groupMessageMap[activatedGroupId] || [];
        const renderedLatestMessageTimestamp = currentChatMessages[currentChatMessages.length - 1]?.timestamp;
        const latestMessageTimestamp = groupLatestMessageTimestampMap[activatedGroupId];
        // ignore same message
        let isSame = false;
        for (let i = oldMessages.length - 1; i > -1; i--) {
            const msg = oldMessages[i];
            // image message
            if (message.cmd === 2 && msg.cmd === 2 && message.payload.includes(msg.filename)) {
                isSame = true;
                break;
            }
            // other message
            if (message.client_timestamp === msg.client_timestamp) {
                isSame = true;
                break;
            }
        }
        if (isSame) return;

        yield put(
            updateLatestMessageTimestamp({
                channel,
                timestamp: message.timestamp,
            })
        );
        if (activatedGroupId === group.channel_id) {
            const isNotNewestMessage = renderedLatestMessageTimestamp < latestMessageTimestamp;
            if (activatedGroupId === channel && currentChatMessages.length >= RENDER_LIMIT && isNotNewestMessage) {
                return;
            }
        }
        // update messages
        const newMessages = yield fillMessageExtra([message]);
        let messages = splitMessagesByDay([...oldMessages, ...newMessages]);

        if (activatedGroupId !== group.channel_id && messages.length > 50) {
            messages = messages.filter((m, index) => {
                return index >= messages.length - 20;
            });
        }
        // update no message flag
        yield put(updateNoMessage(false));

        yield put(
            updateGroupMessages({
                groupId: channel,
                messages,
            })
        );
        // update unread count
        const oldUnread = group?.unread || 0;
        yield put(updateGroupUnreadCount({ channel_id: channel, unread: oldUnread + 1 }));
        callback && callback();
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// function* heartbeatTimer() {
//     while (true) {
//         try {
//             const token = localStorage.getItem(tokenKey);
//             yield call(post, `${baseUrl}/account/heartbeat`, { token });
//             yield delay(15000);
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }

// update group members
function* updateGroupMembersExecuter() {
    try {
        const channel_id = yield select((state) => state.Chat.activatedGroupId);
        yield put(fetchGroupMembers({ channel_id }));
    } catch (error) {
        console.log(error);
    }
}

// fetch unread history messages
function* fetchUnreadHistoryMessagesExecuter(action) {
    try {
        const messagesAfter = yield call(getMessagesAfterTimestampApi, action.payload);

        // update messages
        const newMessages = yield fillMessageExtra(messagesAfter.messages);
        const messages = splitMessagesByDay([...newMessages]);
        yield put(
            updateGroupMessages({
                groupId: action.payload.channel,
                messages,
            })
        );
    } catch (error) {
        console.log(error);
    }
}

// login lobby
function* loginLobby() {
    try {
        const token = localStorage.getItem(TOKEN_KEY);
        yield call(lobbyAccountLoginApi, token);
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

// lobby heartbeat
function* heartbeatForLobbyTimer() {
    while (true) {
        try {
            yield delay(15000);
            yield call(lobbyAccountHeartbeatApi);
        } catch (error) {
            console.log("saga error: ", error);
            yield put(updateChatError(`Error: ${error}`));
        }
    }
}

function* getNftMarketplace(contract) {
    try {
        if (!contract) {
            return null;
        }
        const res = yield getCollectionOverviewApi({ contract });
        if (res.errno === 0) {
            yield put(updateNftMarketplace({ contract, marketplace: res.data }));
        }
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

function* reduceCurrentMessagesExecuter(action) {
    const { count, type } = action.payload;
    const activatedGroupId = yield select((state) => {
        return state.Chat.activatedGroupId;
    });
    const groupMessageMap = yield select((state) => {
        return state.Chat.groupMessageMap;
    });
    const currentChatMessages = groupMessageMap[activatedGroupId];
    let reducedMessages;
    if (type === "latest")
        reducedMessages = currentChatMessages.filter((m, index) => {
            return index >= currentChatMessages.length - count;
        });
    else
        reducedMessages = currentChatMessages.filter((m, index) => {
            return index <= count;
        });
    const oldestMessageTimestamp = reducedMessages[0].timestamp;
    yield put(updateOldestMessageTimestamp(oldestMessageTimestamp));
    yield put(
        updateGroupMessages({
            groupId: activatedGroupId,
            messages: reducedMessages,
        })
    );
}

function* fetchGroupMessagesPure(channel) {
    try {
        const recentMessages = yield call(getChannelRecentMessagesApi, {
            channel,
            count: MESSAGE_NUMBER,
        });

        if (!recentMessages.messages) {
            return;
        }

        // sort by timestamp
        const sortedMessages = recentMessages.messages.sort((a, b) => {
            return moment(b.timestamp).isBefore(a.timestamp) ? 1 : -1;
        });

        // oldest message
        // yield put(updateOldestMessageTimestamp(sortedMessages[0].timestamp));
        yield put(
            updateLatestMessageTimestamp({
                channel,
                timestamp: sortedMessages[sortedMessages.length - 1].timestamp,
            })
        );

        const messageWithExtra = yield fillMessageExtra(sortedMessages);

        // update messages
        yield put(
            updateGroupMessages({
                groupId: channel,
                messages: splitMessagesByDay(messageWithExtra),
            })
        );
    } catch (error) {
        console.log("saga error: ", error);
        yield put(updateChatError(`Error: ${error}`));
    }
}

function* fetchGroupsInfo() {
    try {
        const groups = yield select((state) => state.Chat.groups);
        for (let index = 0; index < groups.length; index++) {
            const group = groups[index];
            const { channel_id } = group;
            yield put(fetchGroupMembers({ channel_id }));
            yield call(fetchGroupMessagesPure, channel_id);
        }
    } catch (error) {
        console.log(error);
    }
}

// watchers
function* reUploadImageWatcher() {
    yield takeEvery(RE_UPLOAD_IMAGE, reUploadImageExecuter);
}

function* getGroupListWatcher() {
    yield takeEvery(FETCH_GROUPS, getGroupListExecuter);
}

function* fetchGroupMessagesWatcher() {
    yield takeEvery(FETCH_GROUP_MESSAGES, fetchGroupMessagesExecuter);
}

function* fetchGroupMembersWatcher() {
    yield takeEvery(FETCH_GROUP_MEMBERS, fetchGroupMembersExecuter);
}

function* sendMessageWatcher() {
    yield takeEvery(SEND_MESSAGE, sendMessageExecuter);
}

function* fetchBeforeMessagesWatcher() {
    yield throttle(200, FETCH_BEFORE_MESSAGES, fetchBeforeMessagesExecuter);
}
function* fetchAfterMessagesWatcher() {
    yield throttle(1000, FETCH_AFTER_MESSAGES, fetchAfterMessagesExecuter);
}

function* setLastReadMessageWatcher() {
    yield takeEvery(SET_LAST_READ_MESSAGE, setLastReadMessageExecuter);
}

function* stickEmojiWatcher() {
    yield takeEvery(STICK_EMOJI, stickEmojiExecuter);
}

function* undoStickEmojiWatcher() {
    yield takeEvery(UNDO_STICK_EMOJI, undoStickEmojiExecuter);
}

function* changeGroupWatcher() {
    yield takeEvery(TRIGGER_CHANGE_GROUP, changeGroupExecuter);
}

function* fetchStickerWatcher() {
    yield takeEvery(FETCH_STICKER, fetchStickerExecuter);
}

function* handleWSMessageWatcher() {
    yield takeEvery(HANDLE_WS_MESSAGE, handleWSMessageExecuter);
}

function* startAfterPollWatcher() {
    yield takeEvery(START_AFTER_POLL, startAfterPollExecuter);
}

function* updateGroupMembersWatcher() {
    yield takeEvery(OPEN_GROUP_PROFILE_SIDEBAR, updateGroupMembersExecuter);
}

function* fetchUnreadHistoryMessagesWatcher() {
    yield takeEvery(USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES, fetchUnreadHistoryMessagesExecuter);
}
function* reduceCurrentMessagesWatcher() {
    yield takeEvery(REDUCE_CURRENT_MESSAGES, reduceCurrentMessagesExecuter);
}

function* chatSaga() {
    yield all([
        fork(reUploadImageWatcher),
        fork(getGroupListWatcher),
        fork(fetchGroupMessagesWatcher),
        fork(fetchGroupMembersWatcher),
        fork(sendMessageWatcher),
        fork(fetchBeforeMessagesWatcher),
        fork(fetchAfterMessagesWatcher),
        fork(setLastReadMessageWatcher),
        fork(stickEmojiWatcher),
        fork(undoStickEmojiWatcher),
        fork(changeGroupWatcher),
        fork(fetchStickerWatcher),
        fork(handleWSMessageWatcher),
        fork(startAfterPollWatcher),
        fork(updateGroupMembersWatcher),
        fork(fetchUnreadHistoryMessagesWatcher),
        fork(reduceCurrentMessagesWatcher),
    ]);
}

export default chatSaga;
