import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser, loginUserSuccess } from "../../redux/actions";
import jwtDecode from "jwt-decode";
import { AUTH_USER_KEY, AUTH_USER_LIST_KEY, TOKEN_KEY } from "../../helpers/index";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.capitalizeFirstLetter.bind(this);
    }

    //function for capital first letter of current page pathname
    capitalizeFirstLetter = (string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
        // const currentage = this.capitalizeFirstLetter(this.props.location.pathname);

        if (!window.accountsChangedBound) {
            window.ethereum.on("accountsChanged", (accounts) => {
                localStorage.setItem(AUTH_USER_KEY, "");
                const account = accounts[0];
                const authUsersStorage = localStorage.getItem(AUTH_USER_LIST_KEY);
                const authUsers = authUsersStorage ? JSON.parse(authUsersStorage) : [];
                const currentUserIndex = authUsers.findIndex((authUser) => {
                    return authUser.Address === account;
                });
                if (currentUserIndex !== -1) {
                    const currentUser = authUsers[currentUserIndex];
                    const { exp, token } = currentUser;
                    const userInfo = jwtDecode(token);
                    if (exp > Date.now() / 1000) {
                        localStorage.setItem(TOKEN_KEY, token);
                        localStorage.setItem(AUTH_USER_KEY, JSON.stringify(userInfo));
                        this.props.loginUserSuccess(userInfo);
                        window.location.reload();
                    } else {
                        window.location.href = '/landing'
                    }
                } else {
                    window.location.href = '/landing'
                }
            });
            window.accountsChangedBound = true;
        }

        //set document title according to page path name
        // document.title = "Famchat";
    }

    render() {
        return (
            <React.Fragment>
                <div className="layout-wrapper d-lg-flex">
                    {/* left sidebar menu */}
                    {/* <LeftSidebarMenu /> */}
                    {/* render page content */}
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return state.Auth;
}

const mapDispatchToProps = {
    loginUser,
    loginUserSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
