import { splitMessagesByDay } from "../../helpers";
import {
    CHAT_USER,
    ACTIVE_USER,
    FULL_USER,
    ADD_LOGGED_USER,
    CREATE_GROUP,
    QUOTE_ID,
    UPDATE_ERROR,
    UPDATE_CURRENT_MESSAGES,
    ADD_URL_META,
    UPDATE_UPLOAD_IMAGE,
    DELETE_MESSAGE_BY_ID,
    UPDATE_MESSAGE_NORMAL,
    SET_GROUPS,
    UPDATE_GROUP_MESSAGES,
    UPDATE_GROUP_MEMBERS,
    UPDATE_ACTIVATED_GROUP_ID,
    UPDATE_BEFORE_ENABLE,
    UPDATE_LOADING_MESSAGE,
    UPDATE_LAST_READ_MESSAGE_TIMESTAMP,
    UPDATE_LATEST_MESSAGE_TIMESTAMP,
    UPDATE_VIEW_DIRECTION,
    TRIGGER_CHANGE_GROUP,
    NO_MESSAGES,
    SET_STICKER,
    SET_BEFORE_MESSAGES,
    UPDATE_OLDEST_MESSAGE_TIME,
    SET_NO_MORE_MESSAGE,
    RESET_GROUP_UNREAD_COUNT,
    UPDATE_SENDER_MESSAGE,
    UPDATE_CHANNEL_MEMBER,
    UPDATE_GROUP_UNREAD_COUNT,
    UPDATE_NFT_MARKETPLACE,
    REDUCE_CURRENT_MESSAGES,
    UPDATE_GROUP_BY_ID,
} from "./constants";

const INIT_STATE = {
    active_user: 0,
    users: [],
    groups: [],
    contacts: [],
    activatedGroupId: "",
    groupMessageMap: {},
    groupMemberMap: {},
    groupLatestMessageTimestampMap: {},
    viewDirection: "down",
    quoteMsgId: null,
    error: "",
    currentChatMessages: [],
    fetchBeforeEnable: false,
    loadingMessage: false,
    unreadTimestamp: 0,
    noMessage: false,
    stickerList: [],
    beforeMessages: [],
    oldestMessageTimestamp: 0,
    noMoreMessages: false,
    marketplaceMap: {},
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAT_USER:
            return { ...state };

        case ACTIVE_USER:
            return {
                ...state,
                active_user: action.payload,
                currentChatMessages: state.groups[action.payload].messages,
            };

        case FULL_USER:
            return {
                ...state,
                users: action.payload,
            };

        case ADD_LOGGED_USER:
            const newUser = action.payload;
            return {
                ...state,
                users: [...state.users, newUser],
            };

        case CREATE_GROUP:
            const newGroup = action.payload;
            return {
                ...state,
                groups: [...state.groups, newGroup],
            };
        case QUOTE_ID:
            const quoteMsgId = action.payload;
            return {
                ...state,
                quoteMsgId,
            };
        case UPDATE_ERROR:
            const error = action.payload;
            return {
                ...state,
                error,
            };
        case UPDATE_CURRENT_MESSAGES:
            const messages = action.payload;
            const currentChatMessages = [...state.currentChatMessages, ...messages];
            return {
                ...state,
                currentChatMessages,
            };
        case ADD_URL_META:
            const message = action.payload;
            return {
                ...state,
                currentChatMessages: state.currentChatMessages.map((m) => {
                    if (m.id === message.id) {
                        return message;
                    }
                    return m;
                }),
            };
        case UPDATE_UPLOAD_IMAGE:
            return {
                ...state,
                currentChatMessages: state.currentChatMessages.map((m) => {
                    if (m.id === action.payload.id) {
                        return action.payload;
                    }
                    return m;
                }),
            };
        case DELETE_MESSAGE_BY_ID:
            console.log('delete: ', action.payload)
            return {
                ...state,
                groupMessageMap: {
                    ...state.groupMessageMap,
                    [action.payload.channel_id]: state.groupMessageMap[action.payload.channel_id].filter(m => {
                        return m.timestamp !== action.payload.timestamp
                    })
                }
            };
        case UPDATE_MESSAGE_NORMAL:
            const groupMessagesNeedUpdate = state.groupMessageMap[action.payload.channel_id] || [];
            const newGroupMessagesNeedUpdate = groupMessagesNeedUpdate.map((m) => {
                if (m.timestamp === action.payload.timestamp) {
                    return { ...m, ...action.payload };
                }
                return m;
            });

            return {
                ...state,
                groupMessageMap: {
                    ...state.groupMessageMap,
                    [action.payload.channel_id]: newGroupMessagesNeedUpdate,
                },
                currentChatMessages: action.payload.channel_id === state.activatedGroupId ? newGroupMessagesNeedUpdate : state.currentChatMessages,
            };
        case SET_GROUPS:
            return {
                ...state,
                groups: action.payload,
            };
        case UPDATE_GROUP_MESSAGES:
            const { groupId } = action.payload;

            return {
                ...state,
                groupMessageMap: {
                    ...state.groupMessageMap,
                    [groupId]: action.payload.messages,
                },
            };
        case UPDATE_GROUP_MEMBERS:
            return {
                ...state,
                groupMemberMap: {
                    ...state.groupMemberMap,
                    [action.payload.groupId]: action.payload.members,
                },
            };
        case UPDATE_ACTIVATED_GROUP_ID:
            return {
                ...state,
                activatedGroupId: action.payload,
                currentChatMessages: state.groupMessageMap[action.payload] || [],
            };
        case UPDATE_BEFORE_ENABLE:
            return {
                ...state,
                fetchBeforeEnable: action.payload.enable,
            };
        case UPDATE_LOADING_MESSAGE:
            return {
                ...state,
                loadingMessage: action.payload.loading,
            };
        case UPDATE_LAST_READ_MESSAGE_TIMESTAMP:
            return {
                ...state,
                unreadTimestamp: action.payload.timestamp,
            };
        case UPDATE_LATEST_MESSAGE_TIMESTAMP: {
            const { timestamp, channel } = action.payload;
            const { groupLatestMessageTimestampMap } = state;
            return {
                ...state,
                groupLatestMessageTimestampMap: {
                    ...groupLatestMessageTimestampMap,
                    [channel]: timestamp,
                },
            };
        }
        case TRIGGER_CHANGE_GROUP:
            return {
                ...state,
                currentChatMessages: [],
            };

        case NO_MESSAGES:
            return {
                ...state,
                noMessage: action.payload,
            };
        case SET_STICKER:
            return {
                ...state,
                stickerList: action.payload,
            };
        case SET_BEFORE_MESSAGES:
            return {
                ...state,
                beforeMessages: action.payload,
            };
        case UPDATE_OLDEST_MESSAGE_TIME:
            return {
                ...state,
                oldestMessageTimestamp: action.payload,
            };
        case SET_NO_MORE_MESSAGE:
            return {
                ...state,
                noMoreMessages: action.payload,
            };
        case RESET_GROUP_UNREAD_COUNT:
            return {
                ...state,
                groups: state.groups.map((g) => {
                    return g.channel_id === action.payload.channel_id ? { ...g, unread: 0 } : g;
                }),
            };
        case UPDATE_SENDER_MESSAGE:
            const { channel_id, client_timestamp } = action.payload;
            const groupMessages = state.groupMessageMap[channel_id] || [];
            const newGroupMessages = groupMessages.map((m) => {
                if (m.timestamp === client_timestamp) {
                    return { ...action.payload };
                }
                return m;
            });
            const formattedNewGroupMessages = splitMessagesByDay(newGroupMessages);
            return {
                ...state,
                groupMessageMap: {
                    ...state.groupMessageMap,
                    [channel_id]: formattedNewGroupMessages,
                },
                currentChatMessages: channel_id === state.activatedGroupId ? formattedNewGroupMessages : state.currentChatMessages,
            };
        case UPDATE_CHANNEL_MEMBER:
            const { channel_info, uid } = action.payload;

            const memberMap = state.groupMemberMap[channel_info.channel_id] || {};
            return {
                ...state,
                groupMemberMap: {
                    ...state.groupMemberMap,
                    [channel_info.channel_id]: {
                        ...memberMap,
                        [uid]: channel_info,
                    },
                },
            };
        case UPDATE_GROUP_UNREAD_COUNT:
            const data = action.payload;
            return {
                ...state,
                groups: state.groups.map((g) => {
                    if (g.channel_id === data.channel_id) {
                        return {
                            ...g,
                            unread: data.unread,
                        };
                    }
                    return g;
                }),
            };
        case UPDATE_NFT_MARKETPLACE:
            const { contract, marketplace } = action.payload;
            return {
                ...state,
                marketplaceMap: {
                    ...state.marketplaceMap,
                    [contract]: marketplace,
                },
            };
        case UPDATE_VIEW_DIRECTION:
            return {
                ...state,
                viewDirection: action.payload,
            };

        case UPDATE_GROUP_BY_ID: {
            return {
                ...state,
                groups: state.groups.map((g) => {
                    return g.channel_id === action.payload.channel_id ? action.payload : g;
                }),
            };
        }

        default:
            return { ...state };
    }
};

export default Chat;
