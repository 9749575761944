import * as CONSTANT from "./constants";

export const getNFTByAccount = () => ({
	type: CONSTANT.GET_NFT,
})

export const setNFT = (data) => ({
	type: CONSTANT.SET_NFT,
	payload: data
})

export const setAllNFT = (data) => ({
	type: CONSTANT.SET_ALL_NFT,
	payload: data
})

export const setAccountInfo = (data) => ({
	type: CONSTANT.SET_ACCOUNT_INFO,
	payload: data
})

export const queryAccountInfo = () => ({
	type: CONSTANT.QUERY_ACCOUNT_INFO,
})

export const updateAccountInfo = (data) => ({
	type: CONSTANT.UPDATE_ACCOUNT_INFO,
	payload: data
})

export const getTokenCount = () => ({
	type: CONSTANT.GET_TOKEN_COUNT
})

export const getTokenRecord = (data) => ({
	type: CONSTANT.GET_TOKEN_RECORD,
	payload: data
})

export const updateTokenCount = (data) => ({
	type: CONSTANT.UPDATE_TOKEN_COUNT,
	payload: data
})

export const updateTokenRecord = (data) => ({
	type: CONSTANT.UPDATE_TOKEN_RECORD,
	payload: data
})