import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase";
import { AUTH_USER_KEY, AUTH_USER_LIST_KEY, TOKEN_KEY } from "../../helpers";
import jwtDecode from "jwt-decode";

import { LOGIN_USER, LOGOUT_USER, USER_SWITCH } from "./constants";

import { loginUserSuccess, apiError, switchUser } from "./actions";
import { authApi, loginApi } from "../../helpers/api";
import { clearCurrentUserStorage } from "../../helpers/authUtils";

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();
const Domain = "famchat.io";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { wallet, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            // const response = yield call(fireBaseBackend.loginUser, username, password);
            // yield put(loginUserSuccess(response));
        } else {
            const address = wallet.account;
            const chain_type = yield select((state) => state.Auth.chainType);
            const inviter = yield select((state) => state.Auth.inviter);
            const authUsersStorage = localStorage.getItem(AUTH_USER_LIST_KEY);
            const authUsers = authUsersStorage ? JSON.parse(authUsersStorage) : [];
            const currentUserIndex = authUsers.findIndex((authUser) => {
                return authUser.Address === address;
            });
            if (currentUserIndex !== -1) {
                const currentUser = authUsers[currentUserIndex];
                const { exp, token } = currentUser;
                const userInfo = jwtDecode(token);
                if (exp > Date.now() / 1000) {
                    localStorage.setItem(TOKEN_KEY, token);
                    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(userInfo));
                    yield put(loginUserSuccess(userInfo));
                    // yield put(switchUser(false));
                    history.push("/");
                    if (history.location.pathname === "/") {
                        window.location.reload();
                    }
                    return;
                    //todo handle heartBeat
                } else {
                    authUsers.splice(currentUserIndex, 1);
                }
            }
            // login
            const response = yield call(loginApi, {
                platform_id: address,
                platform: "eth",
                domain: Domain,
            });
            const nonce = response.nonce;
            const message = response.login_msg;
            const signature = yield window.ethereum.request({
                method: "personal_sign",
                params: [message, address],
            });
            // auth token
            const auth = yield call(authApi, {
                platform_id: address,
                platform: "eth",
                nonce,
                signature,
                invitee: inviter,
                domain: Domain,
            });
            const token = auth.token;
            const userInfo = { ...jwtDecode(token), token };
            authUsers.push(userInfo);
            yield localStorage.setItem(AUTH_USER_LIST_KEY, JSON.stringify(authUsers));
            yield localStorage.setItem(AUTH_USER_KEY, JSON.stringify(userInfo));
            yield localStorage.setItem(TOKEN_KEY, token);
            yield put(loginUserSuccess(userInfo));

            history.push("/");
            if (history.location.pathname === "/") {
                window.location.reload();
            }
        }
    } catch (error) {
        console.log(error);
        yield put(apiError(`Login Failed: ${error}`));
        yield put(switchUser(false));
        return;
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        clearCurrentUserStorage();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            yield call(fireBaseBackend.logout);
        }
        const lng = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng", lng);
        yield call(() => {
            history.push("/landing");
        });
    } catch (error) {
        console.log(error);
    }
}

function* setUserSwitching({ payload: { show } }) {
    yield put(switchUser(show));
}

export function* watchUserSwitching() {
    yield takeEvery(USER_SWITCH, setUserSwitching);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    // yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    // yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
