import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
    getAllNFTByAccountApi,
    getTokenCountApi,
    getTokenRecordsApi,
    nftWhitelistApi,
    queryAccountInfoApi,
    updateAccountInfoApi,
} from "../../helpers/api";
import { fetchGroups } from "../actions";
import * as ACTION from "./actions";
import * as CONSTANT from "./constants";
import moment from "moment";

// executers
// Get user's nfts
function* getNFTsExecuter() {
    try {
        const account = yield select((state) => state.Auth.user.Address);
        const profileNfts = yield select((state) => state.Profile.nfts);
        if (!account || (profileNfts && profileNfts.length > 0)) {
            return;
        }
        const nfts = yield call(nftWhitelistApi, { page: 0, size: 99 });
        yield put(ACTION.setNFT({ nfts: nfts.data || [] }));
        const allNfts = yield call(getAllNFTByAccountApi, { account });
        const list = allNfts.map((n) => {
            const matched = nfts.data.find((nft) => nft.token_address === n.tokenAddress);

            return !!matched
                ? {
                      ...n,
                      ...matched,
                      supported: !!matched,
                  }
                : {
                      ...n,
                      supported: !!matched,
                  };
        });
        yield put(ACTION.setAllNFT({ allNfts: list }));
    } catch (error) {
        console.log(error);
    }
}

// Get user's account info
function* getAccountInfoExecuter() {
    try {
        const account = yield select((state) => state.Profile.account);
        const isVisitor = yield select((state) => state.Auth.isVisitor);

        if ((account && account.addr) || isVisitor) {
            return;
        }
        const res = yield call(queryAccountInfoApi);
        if (res.addr) {
            yield put(ACTION.setAccountInfo({ accountInfo: res }));
        }
    } catch (error) {
        console.log(error);
    }
}

// Update user's account info
function* updateAccountInfoExecuter({ payload }) {
    try {
        const data = payload;
        yield call(updateAccountInfoApi, data);
        const res = yield call(queryAccountInfoApi);
        if (res.addr) {
            yield put(ACTION.setAccountInfo({ accountInfo: res }));
            // yield put(fetchGroups());
        }
    } catch (error) {
        console.log(error);
    }
}

function* getTokenCountExecuter() {
    try {
        const res = yield call(getTokenCountApi);
        yield put(ACTION.updateTokenCount(res));
    } catch (error) {
        console.log(error);
    }
}

function* getTokenRecordExecuter({ payload }) {
    try {
        const res = yield call(getTokenRecordsApi, payload);
        if (!res.records) {
            return
        }
        const tokenReasonMap = {
            '1': 'Base',
            '2': 'Award',
            '3': 'Respect',
            '4': 'Reward',
            '5': 'Lock',
            '6': 'Unlock',
        }
        // flat data
        const records = []
        res.records.forEach(record => {
            records.push({
                count: record.Score,
                reason: tokenReasonMap[record.Reason],
                time: moment(record.Timestamp).format('MMM D, YYYY'),
                uid: record.UID,
                epoch: record.Timestamp,
            })
        })
        const r = records.sort((a,b) => b.epoch - a.epoch).slice(0, 5)
        yield put(ACTION.updateTokenRecord(r));
    } catch (error) {
        console.log(error);
    }
}

// watchers
function* getNFTsWatcher() {
    yield takeEvery(CONSTANT.GET_NFT, getNFTsExecuter);
}
function* getAccountInfoWatcher() {
    yield takeEvery(CONSTANT.QUERY_ACCOUNT_INFO, getAccountInfoExecuter);
}
function* updateAccountInfoWatcher() {
    yield takeEvery(CONSTANT.UPDATE_ACCOUNT_INFO, updateAccountInfoExecuter);
}
function* getTokenCountWatcher() {
    yield takeEvery(CONSTANT.GET_TOKEN_COUNT, getTokenCountExecuter);
}
function* getTokenRecordWatcher() {
    yield takeEvery(CONSTANT.GET_TOKEN_RECORD, getTokenRecordExecuter);
}

function* profileSaga() {
    yield all([
        fork(getNFTsWatcher),
        fork(getAccountInfoWatcher),
        fork(updateAccountInfoWatcher),
        fork(getTokenCountWatcher),
        fork(getTokenRecordWatcher),
    ]);
}

export default profileSaga;
