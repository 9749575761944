import moment from "moment";
import ETHSvg from "../assets/images/eth.svg";
import Identicon from "identicon.js";
import { version } from "../../package.json";
import NoImg from "../assets/images/noImg.png";

/**
 * format storage key
 * @param {String} key
 * @returns
 */
export const storageKeyFormatter = (key) => {
    return `FAMCHAT_PROD_${key}_v${version}`
}

export const THEME_KEY = storageKeyFormatter("THEME");
export const AUTH_USER_KEY = storageKeyFormatter("AUTH_USER");
export const AUTH_USER_LIST_KEY = storageKeyFormatter("AUTH_USER_LIST");
export const TOKEN_KEY = storageKeyFormatter("TOKEN");
export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";

/**
 * identicon generator
 * @param {String} hash
 * @returns
 */
export const identiconGenerator = (hash) => {
    if (!hash) {
        return;
    }
    // 配置项
    let options = {
        size: 80,
        margin: 0.15,
        format: "svg",
        saturation: 0.5,
    };

    let base64 = new Identicon(hash, options).toString();

    let src = `data:image/svg+xml;base64,${base64}`;

    return src;
};

/**
 * bit size formatter
 * @param {Number} num
 * @returns
 */
export const BitFormatter = (num) => {
    const n = Number(num);
    if (!n) {
        return "";
    }
    if (n >= 1024 * 1024 * 1024) {
        return `${Math.round(n / (1024 * 1024 * 1024))} G`;
    }
    if (n >= 1024 * 1024) {
        return `${Math.round(n / (1024 * 1024))} M`;
    }
    if (n >= 1024) {
        return `${Math.round(n / 1024)} K`;
    }
    return `${n} B`;
};

/**
 * transform ipfs address to url address
 * @param {String} address IPFS address
 * @returns url address
 */
export const transformIPFSAddress = (address) => {
    if (!address) {
        return;
    }
    const server = "https://ipfs.io/ipfs/";
    const hash = address.split("//")[1];
    return `${server}${hash}`;
};

/**
 * convert NFT's Address to url address
 * @param {String} address NFT's address
 * @returns url address
 */
export const convertNFTAddress = (address) => {
    if (!address) {
        return NoImg;
    }
    if (address.indexOf("ipfs://") > -1) {
        return transformIPFSAddress(address);
    }
    return address;
};

/**
 * split messages by date
 * @param {Array} messages
 * @returns messages with date
 */
export const splitMessagesByDay = (messages) => {
    const arr = [];
    const today = moment().startOf("day");
    for (let i = 0; i < messages.length; i++) {
        const cur = messages[i];
        const next = messages[i + 1];
        const curDate = moment(cur.timestamp).get("date");

        // first message date
        // if (i === 0 && !cur.isDate) {
        //   arr.push({
        //     isDate: true,
        //     date: moment(cur.timestamp).format('YYYY-MM-DD'),
        //     isToday: moment(cur.timestamp).isSame(today, 'd'),
        //   })
        // }
        arr.push({
            ...cur,
            time: moment(cur.timestamp).format("HH:mm:ss"),
            messageType: convertMessageType(cur.cmd),
        });

        // jump date message
        if (!next || cur.isDate || next.isDate) {
            continue;
        }

        // compare the date of two messages
        if (next) {
            const nextDate = moment(next.timestamp).get("date");
            if (curDate !== nextDate) {
                arr.push({
                    isDate: true,
                    date: moment(next.timestamp).format("YYYY-MM-DD"),
                    isToday: moment(next.timestamp).isSame(today, "d"),
                });
            }
        }
    }
    return arr;
};

/**
 * Switch id / emoji
 * @param {String} key id / emoji
 * @returns id / emoji
 */
export const emojiMapTool = (key) => {
    const map = [
        { id: 1, emoji: "like" },
        { id: 2, emoji: "respect" },
    ];
    const e = map.find((e) => e.id === key || e.emoji === key);

    if (!e) {
        return;
    }
    if (key === e.id) {
        return e.emoji;
    }
    return e.id;
};

/**
 * Get NFT name by id
 * @param {String} id nft id
 * @returns nft name
 */
export const getNFTNameById = (id) => {
    const map = {
        phantabear: "PhantaBear",
        azuki: "Azuki",
        bayc: "Bored Ape Yacht Club",
    };

    return map[id] || id;
};

/**
 * Convert cmd to message type
 * @param {Number} cmd
 * @returns message type
 */
export const convertMessageType = (cmd) => {
    switch (cmd) {
        case 0:
            return "textMessage";

        case 1:
            return "urlMessage";

        case 2:
            return "imageMessage";

        case 3:
            return "fileMessage";

        case 4:
            return "stickerMessage";

        default:
            return "textMessage";
    }
};

export const throttle = (fn, ms, shouldRunFirst = true) => {
    let interval = null;
    let isPending;
    let args;

    return (..._args) => {
        isPending = true;
        args = _args;

        if (!interval) {
            if (shouldRunFirst) {
                isPending = false;
                // @ts-ignore
                fn(...args);
            }

            interval = setTimeout(() => {
                if (!isPending) {
                    clearTimeout(interval);
                    interval = null;
                    return;
                }

                isPending = false;
                fn(...args);
            }, ms);
        }
    };
};

// format empty nick
export const formatUserNick = (user) => {
    if (!user) {
        return null;
    }
    let nick = user.nick;
    if (!nick && user.member_id) {
        const id = user.member_id.toString();
        nick = `Fam#${id.slice(id.length - 4)}`;
    }
    return nick;
};

// format marketplace number
export const formatMarketplaceNumber = (number) => {
    if (number > 1000) {
        let n = (number / 1000).toFixed(2);
        if (n.endsWith("0")) {
            n = (number / 1000).toFixed(1);
        }
        return `${n}K`;
    }
    if (number > 1000000) {
        let n = (number / 1000000).toFixed(2);
        if (n.endsWith("0")) {
            n = (number / 1000000).toFixed(1);
        }
        return `${n}M`;
    }

    return number;
};

// get token icon
export const getTokenIcon = (nft) => {
    if (!nft || !nft.chain) {
        return ETHSvg;
    }
    const { chain } = nft;
    if (chain.test(/eth/i)) {
        return ETHSvg;
    }
    return ETHSvg;
};

/**
 * get url specific param
 * @param {String} key g: group, i: inviter
 */
export const getUrlParams = (key) => {
    const pathname = window.location.pathname;
    const arr = pathname.split("/");
    if (arr.length > 2 && arr[1] === key) {
        return decodeURI(arr[2]);
    }
    if (arr.length > 2 && arr[2] === key) {
        return decodeURI(arr[3]);
    }
    return "";
};

/**
 * get member nickname
 * @param {Object} member member info
 * @returns nickname
 */
export const getMemberNickname = (member) => {
    if (!member) {
        return "";
    }
    if (member.nick) {
        return member.nick;
    }
    if (member.addr) {
        return member.addr.slice(0, 6);
    }
    if (member.member_addr) {
        return member.member_addr.slice(0, 6);
    }
    return "";
};
