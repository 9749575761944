import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    USER_SWITCH,
    IS_VISITOR,
    SET_INVITER,
    SET_CHAIN_TYPE,
} from "./constants";

export const loginUser = (wallet, history) => ({
    type: LOGIN_USER,
    payload: { wallet, history },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const registerUser = (user) => ({
    type: REGISTER_USER,
    payload: { user },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error,
});
export const switchUser = (show) => ({
    type: USER_SWITCH,
    payload: show,
});

export const setIsVisitor = () => ({
    type: IS_VISITOR,
})

export const setInviter = (data) => ({
    type: SET_INVITER,
    payload: data
})

export const setChainType = (data) => ({
    type: SET_CHAIN_TYPE,
    payload: data
})
