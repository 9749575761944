export const GET_NFT = 'GET_NFT';
export const SET_NFT = 'SET_NFT';
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const QUERY_ACCOUNT_INFO = 'QUERY_ACCOUNT_INFO';
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const SET_ALL_NFT = 'SET_ALL_NFT';
export const GET_TOKEN_COUNT = 'GET_TOKEN_COUNT';
export const GET_TOKEN_RECORD = 'GET_TOKEN_RECORD';
export const UPDATE_TOKEN_COUNT = 'UPDATE_TOKEN_COUNT';
export const UPDATE_TOKEN_RECORD = 'UPDATE_TOKEN_RECORD';
