import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import chatSaga from './chat/saga';
import profileSaga from './profile/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        chatSaga(),
        profileSaga(),
    ]);
}
