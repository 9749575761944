import {
    SET_ACTIVE_TAB,
    OPEN_USER_PROFILE_SIDEBAR,
    CLOSE_USER_PROFILE_SIDEBAR,
    SET_CONVERSATION_NAME_IN_OPEN_CHAT,
    OPEN_GROUP_PROFILE_SIDEBAR,
    CLOSE_GROUP_PROFILE_SIDEBAR,
    TOGGLE_PROFILE,
    TOGGLE_GROUP_DETAIL,
    TOGGLE_NFT_DRAWER,
} from "./constants";

export const setActiveTab = (tabId) => ({
    type: SET_ACTIVE_TAB,
    payload: tabId,
});

export const openUserSidebar = (user) => ({
    type: OPEN_USER_PROFILE_SIDEBAR,
    payload: user,
});

export const closeUserSidebar = () => ({
    type: CLOSE_USER_PROFILE_SIDEBAR,
});

export const openGroupSidebar = () => ({
    type: OPEN_GROUP_PROFILE_SIDEBAR,
});

export const closeGroupSidebar = () => ({
    type: CLOSE_GROUP_PROFILE_SIDEBAR,
});

export const setconversationNameInOpenChat = (conversationName) => ({
    type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
    payload: conversationName,
});

export const toggleProfileOpen = () => ({
    type: TOGGLE_PROFILE,
});

export const toggleGroupDetail = () => ({
    type: TOGGLE_GROUP_DETAIL,
})

export const toggleNFTDrawer = () => ({
    type: TOGGLE_NFT_DRAWER,
})
