// @flow
import {
    SET_ACTIVE_TAB,
    OPEN_USER_PROFILE_SIDEBAR,
    CLOSE_USER_PROFILE_SIDEBAR,
    SET_CONVERSATION_NAME_IN_OPEN_CHAT,
    OPEN_GROUP_PROFILE_SIDEBAR,
    CLOSE_GROUP_PROFILE_SIDEBAR,
    TOGGLE_PROFILE,
    TOGGLE_GROUP_DETAIL,
    TOGGLE_NFT_DRAWER,
} from "./constants";

const INIT_STATE = {
    activeTab: "group",
    userSidebar: false,
    conversationName: "Doris Brown",
    groupSidebar: false,
    profileOpen: false,
    groupDetail: false,
    userSidebarUserInfo: {},
    nftDrawerOpen: false,
};

const Layout = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };

        case OPEN_USER_PROFILE_SIDEBAR:
            return {
                ...state,
                userSidebar: true,
                userSidebarUserInfo: action.payload
            };

        case CLOSE_USER_PROFILE_SIDEBAR:
            return {
                ...state,
                userSidebar: false,
            };

        case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
            return {
                ...state,
                conversationName: action.payload,
            };
        case OPEN_GROUP_PROFILE_SIDEBAR:
            return {
                ...state,
                groupSidebar: true,
            };
        case CLOSE_GROUP_PROFILE_SIDEBAR:
            return {
                ...state,
                groupSidebar: false,
            };
        case TOGGLE_PROFILE:
            return {
                ...state,
                profileOpen: !state.profileOpen,
            };
        case TOGGLE_GROUP_DETAIL:
            return {
                ...state,
                groupDetail: !state.groupDetail,
            }
        case TOGGLE_NFT_DRAWER:
            return {
                ...state,
                nftDrawerOpen: !state.nftDrawerOpen,
            }
        default:
            return state;
    }
};

export default Layout;
