import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import { THEME_KEY, THEME_LIGHT } from './helpers';
import './i18n';

// get theme type from localStorage, default 'light'
const theme = localStorage.getItem(THEME_KEY);
document.body.classList.add(theme || THEME_LIGHT);

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
