import axios from "axios";
import { TOKEN_KEY } from ".";

axios.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem(TOKEN_KEY) || "";
        const headers = config.headers || {};
        headers.token = token;
        return {
            ...config,
            headers,
        };
    },
    function (error) {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    function (response) {
        return response.data ? response.data : response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            case 404:
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }
        return Promise.reject(message);
    }
);

/**
 * Upload image
 * @param {Object} data {formdata}
 * @returns
 */
export const uploadImageApi = (data) => {
    return axios.post("https://spl-it.xyz/nft/img", data, {
        headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
    });
};

// base urls
export const baseUrl = `https://dev-lb.famchat.io`;
const channelBaseUrl = baseUrl;
const senderBaseUrl = baseUrl;
const reciverBaseUrl = baseUrl;
const accountBaseUrl = baseUrl;
const nftBaseUrl = `${baseUrl}/nft`;

// web socket url
export const wsUrl = `wss://dev-lb.famchat.io/pusher/`;

// get token, use closures cache token
const getToken = () => {
    return () => localStorage.getItem(TOKEN_KEY);
};
let token;

/**
 * Deprecated
 * Get Channel List
 * @returns
 */
export const getChannelListApi = () => {
    return axios.get(`${channelBaseUrl}/channels/init`, {
        data: { token: token() },
    });
};

/**
 * Get Channel`s information
 * @returns
 */
export const getChannelInfoApi = () => {
    token = getToken();
    return axios.post(`${channelBaseUrl}/channels/channels`, {
        token: token(),
    });
};

/**
 * Get Channel Members
 * @param {Object} param0 {channel_id}
 * @returns
 */
export const getChannelMembersApi = ({ channel_id }) => {
    return axios.post(`${channelBaseUrl}/channels/members`, {
        token: token(),
        channel_id,
    });
};

/**
 * Send message
 * @param {Object} data {token, channel, ...message}
 * @returns
 */
export const sendMessageApi = (data) => {
    return axios.post(`${senderBaseUrl}/sender/send`, {
        token: token(),
        ...data,
    });
};

/**
 * Get channel's recent messages
 * @param {Object} data {token, channel, count}
 * @returns
 */
export const getChannelRecentMessagesApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/latest`, {
        token: token(),
        ...data,
    });
};

/**
 * Get Channel Messages AFTER Timestamp
 * @param {Object} data {token, channel, user, count, timestamp}
 * @returns
 */
export const getMessagesAfterTimestampApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/after`, {
        token: token(),
        ...data,
    });
};

/**
 * Get Channel Messages BEFORE Timestamp
 * @param {Object} data {token, channel, user, count, timestamp}
 * @returns
 */
export const getMessagesBeforeTimestampApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/before`, {
        token: token(),
        ...data,
    });
};

/**
 * Get user's last read message by channel
 * @param {Object} data {channel, user}
 * @returns message
 */
export const getLastReadMessageApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/unread`, {
        token: token(),
        ...data,
    });
};

/**
 * Set user's last read message by channel
 * @param {Object} data {channel, timestamp}
 * @returns message
 */
export const setLastReadMessageApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/update_unread`, {
        token: token(),
        ...data,
    });
};

/**
 * Get unread message count
 * @param {Object} data {channel}
 * @returns count
 */
export const getUnreadMessageCountApi = (data) => {
    return axios.post(`${reciverBaseUrl}/reciver/unread_count`, {
        token: token(),
        ...data,
    });
};

/**
 * Get NFTs by account
 * @param {Object} data { account }
 * @returns NFT list
 */
export const getNFTByAccountApi = (data) => {
    return axios.get(`${nftBaseUrl}/account`, { params: data });
};

/**
 * Get all NFTs by account
 * @param {Object} data { account }
 * @returns NFT list
 */
export const getAllNFTByAccountApi = (data) => {
    return axios.get(`${nftBaseUrl}/all/account`, { params: data });
};

/**
 * Stick emoji to message
 * @param {Object} data {channel, message's timestamp, likeId}
 * @returns
 */
export const stickEmojiApi = (data) => {
    return axios.post(`${senderBaseUrl}/sender/like`, {
        token: token(),
        ...data,
    });
};

/**
 * Undo stick emoji to message
 * @param {Object} data {channel, message's timestamp, likeId}
 * @returns
 */
export const undoStickEmojiApi = (data) => {
    return axios.post(`${senderBaseUrl}/sender/unlike`, {
        token: token(),
        ...data,
    });
};

/**
 * Query account info
 * @returns account info
 */
export const queryAccountInfoApi = () => {
    token = getToken();
    return axios.post(`${accountBaseUrl}/account/info`, { token: token() });
};

/**
 * Update account info
 * @param {Object} data {nick, profile_nft, profile_icon}
 * @returns
 */
export const updateAccountInfoApi = (data) => {
    return axios.post(`${accountBaseUrl}/account/update_info`, {
        token: token(),
        ...data,
    });
};

/**
 * Get all memes
 * @returns memes
 */
export const fetchMemeApi = () => {
    return axios.post(`${baseUrl}/misc/meme`, {
        token: token(),
    });
};

/**
 * get nft of channel
 * @param {*} data { channel_id }
 * @returns
 */
export const getNftOfChannelApi = (data) => {
    return axios.post(`${baseUrl}/channels/nfts_of_channel`, {
        ...data,
        token: token(),
    });
};

/**
 * update channel avatar
 * @param {*} data { channel_id, avata }
 * @returns
 */
export const updateChannelAvatarApi = (data) => {
    return axios.post(`${baseUrl}/channels/update_member_avata`, {
        ...data,
        token: token(),
    });
};

/**
 * send login to lobby
 * @returns
 */
export const lobbyAccountLoginApi = (token) => {
    return axios.post(`${baseUrl}/lobby/login`, {
        token,
    });
};

/**
 * send heartbeat to keep alive
 * @returns
 */
export const lobbyAccountHeartbeatApi = () => {
    return axios.post(`${baseUrl}/lobby/heartbeat`, {
        token: token(),
    });
};

/**
 * get token
 * @returns
 */
export const getTokenCountApi = () => {
    return axios.post(`${baseUrl}/scoreboard/score`, {
        token: token(),
    });
};

/**
 * get token records
 * @param {*} data { start, count }
 * @returns
 */
export const getTokenRecordsApi = (data) => {
    return axios.post(`${baseUrl}/scoreboard/score_record`, {
        token: token(),
        ...data,
    });
};

// https://webapi.nftscan.com/nftscan/getNftPlatformInfo?keyword=0xd1258db6ac08eb0e625b75b371c023da478e94a9

export const getMarketplaceInfoApi = ({ contract }) => {
    return axios.get(`https://webapi.nftscan.com/nftscan/getNftPlatformInfo`, {
        params: { keyword: contract },
        headers: { chain: "ETH" },
    });
};

export const getCollectionOverviewApi = ({ contract }) => {
    return axios.get(`${baseUrl}/nft/collection/overview`, {
        params: { token_address: contract },
    });
};

/**
 * Claim confirmation
 * @param {*} data { recv_addr, amount, timestamp }
 * @returns
 */
export const claimConfirmationApi = (data) => {
    return axios.post(`${baseUrl}/reward/withdraw`, {
        // token: token(),
        ...data,
    });
};

/**
 * login
 * @param {*} params { address, chain_type }
 * @returns nonce
 */
export const loginApi = (params) => {
    return axios.get(`${baseUrl}/auth/login`, {
        params,
    });
};

/**
 * auth
 * @param {*} params { address, nonce, signature, invitee, chain_type }
 * @returns token
 */
export const authApi = (params) => {
    return axios.get(`${baseUrl}/auth/authorization`, {
        params,
    });
};

/**
 * mobile auth
 * @param {*} params { address, nonce, signature, invitee, chain_type }
 * @returns token
 */
export const authMobileApi = (params) => {
    return axios.get(`${baseUrl}/auth/mobile_authorization`, {
        params,
    });
};

/**
 * whitelist of nft
 * @param {*} params { page, size }
 * @returns nfts
 */
export const nftWhitelistApi = (params) => {
    return axios.get(`${baseUrl}/nft/whitelist/list`, {
        params,
    });
};

/**
 * get invite leader board
 * @param {*} params
 * @returns
 */
export const inviteLeaderBoardApi = (params) => {
    return axios.get(`${baseUrl}/sns/invite/leader_board`, {
        params,
    });
};

/**
 * get my invite list
 * @param {*} params
 * @returns
 */
export const getInviteRecordApi = () => {
    return axios.get(`${baseUrl}/sns/invite/record_list`, {
        params: { token: token() },
    });
};

/**
 * request twitter authorization
 * @returns
 */
export const requestTwitterAuthApi = () => {
    return axios.get(`${baseUrl}/sns/twitter/request_authorize`, {
        params: { token: token() },
    });
};

/**
 * request discord authorization
 * @returns
 */
export const requestDiscordAuthApi = () => {
    return axios.get(`${baseUrl}/sns/discord/request_authorize`, {
        params: { token: token() },
    });
};

/**
 * get aws config
 * @returns aws config
 */
export const getAWSConfigApi = () => {
    return axios.post(`${baseUrl}/sender/file`, {
        token: token(),
    });
};

/**
 * Get URL Meta information
 * @param {String} url Url address
 * @returns
 */
export const getUrlMetaApi = (url) => {
    return axios.post(`${baseUrl}/reciver/opengraph`, {
        token: token(),
        url,
    });
};

/**
 * Get channel by name
 * @param {Object} {channel_label}
 * @returns channel
 */
export const getChannelByLabelApi = (data) => {
    token = getToken();
    return axios.post(`${baseUrl}/channels/channel_from_label`, {
        token: token(),
        ...data,
    });
};
