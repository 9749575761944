import {
    CHAT_USER,
    ACTIVE_USER,
    FULL_USER,
    ADD_LOGGED_USER,
    CREATE_GROUP,
    QUOTE_ID,
    UPDATE_ERROR,
    UPDATE_CURRENT_MESSAGES,
    ADD_URL_META,
    RE_UPLOAD_IMAGE,
    UPDATE_UPLOAD_IMAGE,
    DELETE_MESSAGE_BY_ID,
    UPDATE_MESSAGE_NORMAL,
    SET_GROUPS,
    FETCH_GROUPS,
    FETCH_GROUP_MESSAGES,
    UPDATE_GROUP_MESSAGES,
    FETCH_GROUP_MEMBERS,
    UPDATE_GROUP_MEMBERS,
    UPDATE_ACTIVATED_GROUP_ID,
    SEND_MESSAGE,
    FETCH_BEFORE_MESSAGES,
    FETCH_AFTER_MESSAGES,
    UPDATE_BEFORE_ENABLE,
    UPDATE_LOADING_MESSAGE,
    GET_LAST_READ_MESSAGE,
    SET_LAST_READ_MESSAGE,
    UPDATE_LAST_READ_MESSAGE_TIMESTAMP,
    UPDATE_LATEST_MESSAGE_TIMESTAMP,
    STICK_EMOJI,
    UNDO_STICK_EMOJI,
    TRIGGER_CHANGE_GROUP,
    REDUCE_CURRENT_MESSAGES,
    NO_MESSAGES,
    FETCH_STICKER,
    SET_STICKER,
    HANDLE_WS_MESSAGE,
    START_AFTER_POLL,
    SET_BEFORE_MESSAGES,
    UPDATE_OLDEST_MESSAGE_TIME,
    SET_NO_MORE_MESSAGE,
    USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES,
    RESET_GROUP_UNREAD_COUNT,
    UPDATE_SENDER_MESSAGE,
    UPDATE_CHANNEL_MEMBER,
    UPDATE_GROUP_UNREAD_COUNT,
    UPDATE_NFT_MARKETPLACE,
    UPDATE_VIEW_DIRECTION,
    UPDATE_GROUP_BY_ID,
} from "./constants";

export const chatUser = () => ({
    type: CHAT_USER,
});

export const activeUser = (userId) => ({
    type: ACTIVE_USER,
    payload: userId,
});

export const setFullUser = (fullUser) => ({
    type: FULL_USER,
    payload: fullUser,
});

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload: userData,
});

export const createGroup = (groupData) => ({
    type: CREATE_GROUP,
    payload: groupData,
});

export const updateQuoteMsgId = (id) => ({
    type: QUOTE_ID,
    payload: id,
});

export const updateChatError = (error) => ({
    type: UPDATE_ERROR,
    payload: error,
});

export const updateCurrentMessages = (messages) => ({
    type: UPDATE_CURRENT_MESSAGES,
    payload: messages,
});

export const addUrlMessageMeta = (message) => ({
    type: ADD_URL_META,
    payload: message,
});

export const reUploadImage = (message) => ({
    type: RE_UPLOAD_IMAGE,
    payload: message,
});

export const updateUploadImageMessage = (message) => ({
    type: UPDATE_UPLOAD_IMAGE,
    payload: message,
});

export const deleteMessageById = (id) => ({
    type: DELETE_MESSAGE_BY_ID,
    payload: id,
});

export const updateMessageNormal = (message) => ({
    type: UPDATE_MESSAGE_NORMAL,
    payload: message,
});

export const fetchGroups = (id) => ({
    type: FETCH_GROUPS,
    payload: id,
});

export const setGroups = (groups) => ({
    type: SET_GROUPS,
    payload: groups,
});

export const fetchGroupMessages = (data) => ({
    type: FETCH_GROUP_MESSAGES,
    payload: data,
});

export const updateGroupMessages = (data) => ({
    type: UPDATE_GROUP_MESSAGES,
    payload: data,
});

export const fetchGroupMembers = (data) => ({
    type: FETCH_GROUP_MEMBERS,
    payload: data,
});

export const updateGroupMembers = (data) => ({
    type: UPDATE_GROUP_MEMBERS,
    payload: data,
});

export const updateActivatedGroupId = (data) => ({
    type: UPDATE_ACTIVATED_GROUP_ID,
    payload: data,
});

export const sendMessage = (data) => ({
    type: SEND_MESSAGE,
    payload: data,
});

export const fetchBeforeMessages = (data) => ({
    type: FETCH_BEFORE_MESSAGES,
    payload: data,
});

export const fetchAfterMessages = (data) => ({
    type: FETCH_AFTER_MESSAGES,
    payload: data,
});

export const updateFetchBeforeEnable = (data) => ({
    type: UPDATE_BEFORE_ENABLE,
    payload: data,
});

export const updateFetchMessageLoading = (data) => ({
    type: UPDATE_LOADING_MESSAGE,
    payload: data,
});

export const getUnreadMessageTimestamp = (data) => ({
    type: GET_LAST_READ_MESSAGE,
    payload: data,
});

export const setUnreadMessageTimestamp = (data) => ({
    type: SET_LAST_READ_MESSAGE,
    payload: data,
});

export const updateUnreadMessageTimestamp = (data) => ({
    type: UPDATE_LAST_READ_MESSAGE_TIMESTAMP,
    payload: data,
});

export const updateLatestMessageTimestamp = (data) => ({
    type: UPDATE_LATEST_MESSAGE_TIMESTAMP,
    payload: data,
});

export const updateViewDirection = (data) => ({
    type: UPDATE_VIEW_DIRECTION,
    payload: data,
});

export const stickEmoji = (data) => ({
    type: STICK_EMOJI,
    payload: data,
});

export const undoStickEmoji = (data) => ({
    type: UNDO_STICK_EMOJI,
    payload: data,
});

export const triggerChangeGroup = (data) => ({
    type: TRIGGER_CHANGE_GROUP,
    payload: data,
});

export const updateNoMessage = (data) => ({
    type: NO_MESSAGES,
    payload: data,
});

export const fetchSticker = () => ({
    type: FETCH_STICKER,
});

export const setSticker = (data) => ({
    type: SET_STICKER,
    payload: data,
});

export const handleWSMessage = (data) => ({
    type: HANDLE_WS_MESSAGE,
    payload: data,
});

export const startAfterPoll = () => ({
    type: START_AFTER_POLL,
});

export const setBeforeMessages = (data) => ({
    type: SET_BEFORE_MESSAGES,
    payload: data,
});

export const updateOldestMessageTimestamp = (data) => ({
    type: UPDATE_OLDEST_MESSAGE_TIME,
    payload: data,
});

export const setNoMoreMessages = (data) => ({
    type: SET_NO_MORE_MESSAGE,
    payload: data,
});

export const fetchUnreadHistoryMessages = (data) => ({
    type: USE_AFTER_FETCH_HISTORY_UNREAD_MESSAGES,
    payload: data,
});

export const resetGroupUnreadCount = (data) => ({
    type: RESET_GROUP_UNREAD_COUNT,
    payload: data,
});

export const updateSenderMessage = (data) => ({
    type: UPDATE_SENDER_MESSAGE,
    payload: data,
});

export const updateChannelMember = (data) => ({
    type: UPDATE_CHANNEL_MEMBER,
    payload: data,
});

export const updateGroupUnreadCount = (data) => ({
    type: UPDATE_GROUP_UNREAD_COUNT,
    payload: data,
});

export const updateNftMarketplace = (data) => ({
    type: UPDATE_NFT_MARKETPLACE,
    payload: data,
});
export const reduceCurrentMessages = (count) => ({
    type: REDUCE_CURRENT_MESSAGES,
    payload: count,
});
export const updateGroupById = (payload) => ({
    type: UPDATE_GROUP_BY_ID,
    payload
})
